import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-stocks/"
            }}>{`All-Stars Stocks`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-stocks-on-the-loose/"
            }}>{`Stocks on the Loose`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mach-1/"
            }}>{`Mach-1`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking industry sectors and stock-market factors, bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $2,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Round-Robin`}</em>{` aims to continually beat the S&P 500 while avoiding deep drawdowns during recessions. The strategy’s algorithm is loosely based on Scott M. Juds SectorSurfer strategies. Under the hood, the strategy switches between separate bull and bear-market models using a market-regime filter. Both models use a low-noise momentum method to rank and select their assets. `}<em parentName="p">{`Round Robin`}</em>{` only rebalances its positions once per month and holds no more than two ETFs simultaneously. With these properties, the strategy is an excellent fit for aggressive investors with a longer time horizon or smaller accounts.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Round-Robin: An aggressive momentum strategy",
                "title": "TuringTrader.com, Round-Robin: An aggressive momentum strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/low-noise-momentum/"
            }}>{`Low-noise momentum strategies`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Round-Robin`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`detect market-regime by combining economic indicators with S&P 500's momentum and volatility`}</li>
        <li parentName="ul">{`in bear markets, use a momentum strategy trading various debt instruments`}</li>
        <li parentName="ul">{`in bull markets, use two momentum strategies: one trading sector ETFs, the other trading style-box ETFs`}</li>
        <li parentName="ul">{`for each momentum strategy`}
          <ul parentName="li">
            <li parentName="ul">{`create a benchmark through equal-weighting the traded assets`}</li>
            <li parentName="ul">{`determine momentum relative to this benchmark`}</li>
            <li parentName="ul">{`use walk-forward optimization to continually adjust the momentum filters`}</li>
          </ul>
        </li>
      </ul>
      <p>{`Our background article, `}<a parentName="p" {...{
          "href": "/articles/low-noise-momentum/"
        }}>{`Low-Noise Momentum Strategies`}</a>{`, describes `}<em parentName="p">{`Round-Robin`}</em>{`'s operation in more detail. We adapted the strategy's mechanics from Scott M. Juds' book `}<em parentName="p">{`Conquering the Seven Faces of Risk`}</em>{`. For those interested in a better understanding of the strategy's rationale, we recommend reading Juds' book.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Round-Robin`}</em>{` typically invests in only two ETFs at a time. While these ETFs cover a wide array of individual companies, the strategy focuses on only one sector and one style. However, as market conditions evolve, `}<em parentName="p">{`Round-Robin`}</em>{` rotates from one asset to the next. Juds calls this concept `}<em parentName="p">{`serial diversification`}</em>{`.`}</p>
      <p><em parentName="p">{`Round-Robins`}</em>{`' serial diversification aims to position the portfolio well to cope with current market conditions. The market-regime detection serves as an additional safeguard, allowing to react swiftly to changes in sentiment, and move the portfolio into a risk-off state. The effectiveness of these mechanisms manifests itself in `}<em parentName="p">{`Round-Robin`}</em>{`'s low beta of less than 0.5.`}</p>
      <p>{`Nonetheless, `}<em parentName="p">{`Round-Robin`}</em>{` is first and foremost a stock-market strategy. Therefore, we see its primary use in conjunction with other strategies, which offer diversification into additional asset classes.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Unlike many strategies that beat the S&P 500 index only over the full economic cycle, `}<em parentName="p">{`Round-Robin`}</em>{` beats its benchmark in most years. Through switching between separate bull- and bear-market portfolios, the strategy further expands its lead on the benchmark during recession periods.`}</p>
      <p>{`The Monte-Carlo analysis shows that `}<em parentName="p">{`Round-Robin`}</em>{` creates an impressive upside over the S&P 500 benchmark while at the same time maintaining a significantly lower risk profile. Notably, `}<em parentName="p">{`Round-Robin`}</em>{` managed to yield positive returns in all of the years simulated and rarely suffered deeper drawdowns than its benchmark.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Round-Robin`}</em>{` trades frequently and will trigger taxable events regularly. Investors cannot expect to hold any assets long enough to qualify for long-term treatment of capital gains. Therefore, the strategy works best in tax-deferred accounts. However, thanks to `}<em parentName="p">{`Round-Robin`}</em>{`'s significant upside, we expect the strategy to even add value in taxable accounts.`}</p>
      <p>{`Because the strategy holds no more than two ETFs simultaneously, it can function as intended with account values as low as $2,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, November 2020`}</a>{`: Initial release.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, October 2022`}</a>{`: Use `}<a parentName="li" {...{
            "href": "/dashboard/market-vane/"
          }}>{`Market Vane`}</a>{` as the market-regime filter, and use `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` as the bear-market strategy.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, January 2023`}</a>{`: Replaced `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` with a modified version that trades on a monthly schedule.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      